/*
**Author: Santosh Kumar Dash
**Author URL: http://santoshdash.epizy.com/
**Github URL: https://github.com/quintuslabs/dashio-admin
*/

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

#content {
    display: flex;
    min-height: 90vh;
    transition: all 0.3s;
    flex-direction: column;
    width: calc(100vw - 270px);
    margin-left: 250px;
}
.layout-Container {
    min-height: 84vh;
}

div#content.active {
    margin-left: 0;
    width: 100%;
}
.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #ddd;
    margin: 40px 0;
}
.bg-flat-color-1 {
    background: #00c292; }
  
  .bg-flat-color-2 {
    background: #ab8ce4; }
  
  .bg-flat-color-3 {
    background: #03a9f3; }
  
  .bg-flat-color-4 {
    background: #fb9678; }
  
  .bg-flat-color-5 {
    background: #66bb6a; }
  
  .bg-flat-color-6 {
    background: #5c6bc0; }
  
  .bg-transparent {
    background-color: transparent; }
  
  .white-color {
    color: #fff; }
  
  .flat-color-1 {
    color: #00c292; }
  
  .flat-color-2 {
    color: #ab8ce4; }
  
  .flat-color-3 {
    color: #03a9f3; }
  
  .flat-color-4 {
    color: #fb9678; }
  
  .flat-color-5 {
    color: #66bb6a; }
  
  .flat-color-6 {
    color: #5c6bc0; }
  
  .bg-twitter {
    background: #1da1f2; }
  
  .bg-facebook {
    background: #3b5998; }
  
  .bg-instagram {
    background: #833ab4; }
  
  .bg- {
    background: #ef5350; }
  
  .bg- {
    background: #4dbd74; }
  
  .bg- {
    background: #66bb6a; }
  
  .bg-white {
    background-color: #fff; }
  
  .fade-5 {
    opacity: 0.5; }
  
  .icon-lg {
    font-size: 4.68em; }
  
  .fw-r {
    font-weight: normal; }
  
  .color-gray {
    color: #868e96; }
  
  .text-up {
    text-transform: uppercase; }
  
  .br-0 {
    border: none !important; }
  

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
    #content {
        margin-left: 0px;
        width: 100vw;
    }
   
}