/*
**Author: Santosh Kumar Dash
**Author URL: http://santoshdash.epizy.com/
**Github URL: https://github.com/quintuslabs/dashio-admin
*/

.main-content-container.p-4.container-fluid {
    display: flex;
    flex-direction: column;
}

.right-panel {
    padding: 1.875em;
}
.card {
    margin-bottom: 1.875em;
    border-radius: 5px !important;
    padding: 0;
    -webkit-box-shadow: 0 0 20px rgba(0,0,0,.08);
    box-shadow: 0 0 20px rgba(0,0,0,.08) ;
    border: none !important;
}

.card .card-body {
    float: left;
    padding: 1.25em;
    position: relative;
    width: 100%;
}

.stat-widget-five {
    min-height: 60px;
}
.stat-widget-five .stat-icon {
    font-size: 50px;
    line-height: 50px;
    position: absolute;
    left: 30px;
    top: 20px;
}
.stat-widget-five .stat-content {
    margin-left: 100px;
}

.dib {
    float: right;
    margin-right: 2vw;
}
.flat-color-1 {
    color: #00c292;
}
.flat-color-2 {
    color: #ab8ce4;
}
.stat-widget-five .stat-text {
    color: #455a64;
    font-size: 20px;
}
.stat-widget-five .stat-heading {
    color: #99abb4;
    font-size: 14px;
}

.flat-color-3 {
    color: #03a9f3;
}
.flat-color-4 {
    color: #fb9678;
}


.table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}
table {
    border-collapse: collapse;
}
.order-table {
    position: relative;
}
.ov-h {
    overflow: hidden;
}


.order-table:after, .order-table:before {
    content: "";
    position: absolute;
    top: 0;
    height: 37px;
    width: 10px;
    background: #e8e9ef;
}



.order-table tr th {
    background: #e8e9ef;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table-stats table td {
    color: #343a40;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    vertical-align: middle;
}
.table-stats table {
    font-family: open sans,sans-serif;
}

.order-table .badge-complete {
    background: #00c292;
}
.order-table .badge {
    color: #fff;
    padding: 10px;
    text-transform: uppercase;
    font-weight: 400;
}
.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}

.order-table .badge-pending {
    background: #fb9678;
}

a.canvasjs-chart-credit {
    visibility: hidden;
}

.tdl-holder {
    margin: 0 auto;
}

.tdl-holder ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tdl-holder li {
    position: relative;
    background-color: transparent;
    list-style: outside none none;
    margin: 0;
    padding: 7px 0;
}

.tdl-holder li:after, .tdl-holder li:before {
    content: "\f142";
    top: 24px;
    position: absolute;
    font: normal normal normal 14px/1 FontAwesome;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    z-index: 99;
    color: #99abb4;
}

.tdl-holder label {
    cursor: pointer;
    display: block;
    line-height: 50px;
    padding: 0 15px;
    padding-left: 30px;
    position: relative;
    margin: 0!important;
    background: #fafafa;
    color: #99abb4;
    font-size: 15px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}

.tdl-holder li:after {
    left: 10px;
}


.tdl-holder li span {
    margin-left: 30px;
    vertical-align: middle;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}

.tdl-holder label a {
    border-radius: 50%;
    color: rgba(153,171,180,.5);
    float: right;
    line-height: normal;
    margin-top: 15px;
    text-align: center;
    text-decoration: none;
    height: 16px;
    width: 20px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}

.fa-remove:before, .fa-close:before, .fa-times:before {
    content: "\f00d";
}



.tdl-holder input[type=checkbox]:checked~span {
    text-decoration: line-through;
}

.messenger-box {
    padding-top: 15px;
}

.messenger-box ul {
    padding-left: 0;
    display: inline-block;
    width: 100%;
    padding-bottom: 15px;
}

.messenger-box li {
    list-style: none;
    padding-bottom: 20px;
}

.messenger-box .avatar {
    width: 64px;
    float: left;
}

.messenger-box .msg-box {
    margin-left: 84px;
}

.messenger-box .inner-box {
    position: relative;
    border-radius: 10px;
    background-color: #f1f2f7;
    font-size: 14px;
    color: #9aa0a4;
    padding: 14px 20px;
    display: table;
}

.messenger-box .inner-box .name {
    font-size: 16px;
    padding-bottom: 10px;
}

.messenger-box .inner-box:after {
    content: "";
    position: absolute;
    top: 10px;
    left: -18px;
    width: 18px;
    height: 18px;
    border-style: solid;
    border-width: 9px;
    border-color: transparent #f1f2f7 transparent transparent;
}

.messenger-box .avatar .send-time {
    font-size: 11px;
    text-align: center;
    padding-top: 5px;
}

.messenger-box .msg-sent .avatar, .messenger-box .msg-sent .msg-box {
    float: right;
}
.messenger-box .avatar {
    width: 64px;
    float: left;
}
.messenger-box .msg-sent .inner-box:after {
    left: inherit;
    right: -18px;
    border-color: transparent transparent transparent #f1f2f7;
}

.messenger-box .send-mgs {
    margin-top: 20px;
    margin-bottom: 9px;
    position: relative;
}

.messenger-box .send-mgs .yourmsg {
    margin-right: 55px;
}

.messenger-box .send-mgs .yourmsg input {
    border: 1px solid #eceff1;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border-radius: 7px;
}

.messenger-box .send-mgs .msg-send-btn {
    background: #03a9f3;
    color: #fff;
    font-size: 28px;
    border-radius: 7px;
    padding: 0;
    text-align: center;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0;
    top: 0;
}

 li.menu-item-has-children a:hover:before {
    border-color: #03a9f3 #03a9f3 transparent transparent;
}


/* width */
#sidebar::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  #sidebar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
  }
  
  /* Handle */
  #sidebar::-webkit-scrollbar-thumb {
    background: #007bff4b;
    border-radius: 2px;
  }